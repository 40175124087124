<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-input-power" />
      {{ $t('forms.transl138')}}
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CForm>
            <h4 class="py-3">{{ $t('forms.transl140')}} </h4>
            <CRow>
              <CCol md="12">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl141')"
                  v-model.trim="$v.form.business1.$model"
                  :isValid="checkIfValid('business1')"
                  :placeholder="$i18n.translate('forms.transl141')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
              <CButton
                @click="getDataFromGus"
                color="primary"
                class="mr-4 my-4"
              >
                {{ $t('forms.transl142')}}
              </CButton>
              <CButton
                @click="getDataFromVies"
                color="primary"
                class="my-4"
              >
                {{ $t('forms.transl143')}}
              </CButton>
              </CCol>
              <CCol md="12">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl144')"
                  v-model.trim="$v.form.nip1.$model"
                  :isValid="checkIfValid('nip1')"
                  :placeholder="$i18n.translate('forms.transl144')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl145')"
                  v-model.trim="$v.form.regon1.$model"
                  :isValid="checkIfValid('regon1')"
                  :placeholder="$i18n.translate('forms.transl145')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl146')"
                  v-model.trim="$v.form.contact_person.$model"
                  :isValid="checkIfValid('contact_person')"
                  :placeholder="$i18n.translate('forms.transl146')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="3">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl147')"
                  v-model.trim="$v.form.phone1a.$model"
                  :isValid="checkIfValid('phone1a')"
                  :placeholder="$i18n.translate('forms.transl147')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="3">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl148')"
                  v-model.trim="$v.form.phone1b.$model"
                  :isValid="checkIfValid('phone1b')"
                  :placeholder="$i18n.translate('forms.transl148')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="3">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl149')"
                  v-model.trim="$v.form.phone1c.$model"
                  :isValid="checkIfValid('phone1c')"
                  :placeholder="$i18n.translate('forms.transl149')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl150')"
                  v-model.trim="$v.form.email1.$model"
                  :isValid="checkIfValid('email1')"
                  :placeholder="$i18n.translate('forms.transl150')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl151')"
                  v-model.trim="$v.form.email2.$model"
                  :isValid="checkIfValid('email2')"
                  :placeholder="$i18n.translate('forms.transl151')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl152')"
                  v-model.trim="$v.form.email3.$model"
                  :isValid="checkIfValid('email3')"
                  :placeholder="$i18n.translate('forms.transl152')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl153')"
                  v-model.trim="$v.form.postal_code1.$model"
                  :isValid="checkIfValid('postal_code1')"
                  :placeholder="$i18n.translate('forms.transl153')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl154')"
                  v-model.trim="$v.form.city1.$model"
                  :isValid="checkIfValid('city1')"
                  :placeholder="$i18n.translate('forms.transl154')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.Ulica*')"
                  v-model.trim="$v.form.street1.$model"
                  :isValid="checkIfValid('street1')"
                  :placeholder="$i18n.translate('forms.Ulica*')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
<!--              <CCol md="4">-->
<!--                <CInput-->
<!--                  maxlength="255"-->
<!--                  :label="Numer*"-->
<!--                  v-model.trim="$v.form.number1.$model"-->
<!--                  :isValid="checkIfValid('number1')"-->
<!--                  :placeholder="Numer budynku"-->
<!--                  :invalidFeedback="$i18n.translate('forms.transl30')"-->
<!--                />-->
<!--              </CCol>-->
            </CRow>

            <CRow>
              <CCol md="4">
                <CSelect id="country_id1"
                         :label="$i18n.translate('forms.transl156')"
                         v-model.trim="$v.form.country_id1.$model"
                         :options="countries"
                >
                </CSelect>
              </CCol>
              <CCol md="4">
                <CSelect id="provincial_id1"
                         :label="$i18n.translate('forms.transl157')"
                         v-model.trim="$v.form.provincial_id1.$model"
                         :options="provincials"
                >
                </CSelect>
              </CCol>
            </CRow>


            <h4 class="py-3">{{ $t('forms.transl158')}} </h4>
            <CRow>
              <CCol md="12">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl159')"
                  v-model.trim="$v.form.business2.$model"
                  :isValid="checkIfValid('business2')"
                  :placeholder="$i18n.translate('forms.transl159')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
<!--              <CCol md="6">-->
<!--                <CInput-->
<!--                  maxlength="255"-->
<!--                  :label="Nip"-->
<!--                  v-model.trim="$v.form.nip2.$model"-->
<!--                  :isValid="checkIfValid('nip2')"-->
<!--                  :placeholder="Numer NIP"-->
<!--                  :invalidFeedback="$i18n.translate('forms.transl30')"-->
<!--                />-->
<!--              </CCol>-->
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl160')"
                  v-model.trim="$v.form.postal_code2.$model"
                  :isValid="checkIfValid('postal_code2')"
                  :placeholder="$i18n.translate('forms.transl160')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl161')"
                  v-model.trim="$v.form.city2.$model"
                  :isValid="checkIfValid('city2')"
                  :placeholder="$i18n.translate('forms.transl161')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl162')"
                  v-model.trim="$v.form.street2.$model"
                  :isValid="checkIfValid('street2')"
                  :placeholder="$i18n.translate('forms.transl162')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
<!--              <CCol md="4">-->
<!--                <CInput-->
<!--                  maxlength="255"-->
<!--                  :label="Numer"-->
<!--                  v-model.trim="$v.form.number2.$model"-->
<!--                  :isValid="checkIfValid('number2')"-->
<!--                  :placeholder="Numer budynku"-->
<!--                  :invalidFeedback="$i18n.translate('forms.transl30')"-->
<!--                />-->
<!--              </CCol>-->
            </CRow>
            <CRow>
              <CCol md="4">
                <CSelect id="country_id2"
                         :label="$i18n.translate('forms.transl163')"
                         v-model.trim="$v.form.country_id2.$model"
                         :options="countries"
                >
                </CSelect>
              </CCol>
              <CCol md="4">
                <CSelect id="provincial_id2"
                         :label="$i18n.translate('forms.transl164')"
                         v-model.trim="$v.form.provincial_id2.$model"
                         :options="provincials"
                >
                </CSelect>
              </CCol>
            </CRow>



            <h4 class="py-3">{{ $t('forms.transl165')}} </h4>
            <CRow>
              <CCol md="12">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl166')"
                  v-model.trim="$v.form.business3.$model"
                  :isValid="checkIfValid('business3')"
                  :placeholder="$i18n.translate('forms.transl166')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
<!--              <CCol md="6">-->
<!--                <CInput-->
<!--                  maxlength="255"-->
<!--                  :label="NIP"-->
<!--                  v-model.trim="$v.form.nip3.$model"-->
<!--                  :isValid="checkIfValid('nip3')"-->
<!--                  :placeholder="Numer NIP"-->
<!--                  :invalidFeedback="$i18n.translate('forms.transl30')"-->
<!--                />-->
<!--              </CCol>-->
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl167')"
                  v-model.trim="$v.form.postal_code3.$model"
                  :isValid="checkIfValid('postal_code3')"
                  :placeholder="$i18n.translate('forms.transl167')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl168')"
                  v-model.trim="$v.form.city3.$model"
                  :isValid="checkIfValid('city3')"
                  :placeholder="$i18n.translate('forms.transl168')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  maxlength="255"
                  :label="$i18n.translate('forms.transl169')"
                  v-model.trim="$v.form.street3.$model"
                  :isValid="checkIfValid('street3')"
                  :placeholder="$i18n.translate('forms.transl169')"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
<!--              <CCol md="4">-->
<!--                <CInput-->
<!--                  maxlength="255"-->
<!--                  :label="Numer"-->
<!--                  v-model.trim="$v.form.number3.$model"-->
<!--                  :isValid="checkIfValid('number3')"-->
<!--                  :placeholder="Numer budynku"-->
<!--                  :invalidFeedback="$i18n.translate('forms.transl30')"-->
<!--                />-->
<!--              </CCol>-->
            </CRow>
            <CRow>
              <CCol md="4">
                <CSelect id="country_id3"
                         :label="$i18n.translate('forms.transl170')"
                         v-model.trim="$v.form.country_id3.$model"
                         :options="countries"
                >
                </CSelect>
              </CCol>
              <CCol md="4">
                <CSelect id="provincial_id3"
                         :label="$i18n.translate('forms.transl171')"
                         v-model.trim="$v.form.provincial_id3.$model"
                         :options="provincials"
                >
                </CSelect>
              </CCol>
            </CRow>

            <CTextarea
              :label="$i18n.translate('forms.transl172')"
              :placeholder="$i18n.translate('forms.transl172')"
              rows="9"
              v-model.trim="$v.form.content.$model"
            />

<!--            <CButton-->
<!--              color="primary"-->
<!--              :disabled="$v.form.$invalid"-->
<!--              @click="submit"-->
<!--            >-->
            <CButton
              color="primary"
              @click="submit"
            >
               {{ $i18n.translate('forms.transl52') }}
            </CButton>
            <CButton
  class="ml-1"
  color="success"
  @click="goBack"
>
  {{ $i18n.translate('forms.transl53') }}
</CButton>

          </CForm>
          <br />
        </CCol>
      </CRow>
    </CCardBody >
  </CCard >
</template >

<script>
import axios from 'axios';
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import Swal from "sweetalert2";
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"

export default {
  data() {
    return {
      form: {
        // name: null,
        // surname: null,
        contact_person: null,
        email1: null,
        email2: null,
        email3: null,
        content: null,
        nip1: null,
        regon1: null,
        business1: null,
        phone1a: null,
        phone1b: null,
        phone1c: null,
        street1: null,
        //number1: null,
        postal_code1: null,
        city1: null,
        country_id1: null,
        provincial_id1: null,
        nip2: null,
        business2: null,
        phone2: null,
        street2: null,
        //number2: null,
        postal_code2: null,
        city2: null,
        country_id2: null,
        provincial_id2: null,
        nip3: null,
        business3: null,
        phone3: null,
        street3: null,
        //number3: null,
        postal_code3: null,
        city3: null,
        country_id3: null,
        provincial_id3: null,
        // countries: null,
        // provincials: null,
      },
      countries: [],
      provincials: [],
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4)
    },
    isValid() {
      return !this.$v.form.$invalid
    },
    isDirty() {
      return this.$v.form.$anyDirty
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      contact_person: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      nip1: {
        required,
        minLength: minLength(1),
        maxLength: 255
      },
      business1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      street1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      // number1: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: 255
      // },
      postal_code1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      city1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      country_id1: {
      },
      provincial_id1: {
      },
      email1: {
        maxLength: 255
      },
      email2: {
        maxLength: 255
      },
      email3: {
        maxLength: 255
      },
      content: {
        maxLength: 255
      },
      regon1: {
        maxLength: 255
      },
      phone1a: {
        maxLength: 255
      },
      phone1b: {
        maxLength: 255
      },
      phone1c: {
        maxLength: 255
      },
      nip2: {
        maxLength: 255
      },
      business2: {
        maxLength: 255
      },
      phone2: {
        maxLength: 255
      },
      street2: {
        maxLength: 255
      },
      // number2: {
      //   maxLength: 255
      // },
      postal_code2: {
        maxLength: 255
      },
      city2: {
        maxLength: 255
      },
      country_id2: {
      },
      provincial_id2: {
      },
      nip3: {
        maxLength: 255
      },
      business3: {
        maxLength: 255
      },
      phone3: {
        maxLength: 255
      },
      street3: {
        maxLength: 255
      },
      // number3: {
      //   maxLength: 255
      // },
      postal_code3: {
        maxLength: 255
      },
      city3: {
        maxLength: 255
      },
      country_id3: {
      },
      provincial_id3: {
      },
    }
  },
  methods: {
    goBack() {
      this.$router.replace({ path: '/clients' })
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    store() {
      let self = this;
      axios.post(this.$apiAdress + '/api/clients?token=' + localStorage.getItem("api_token")+ '&s_lng=' + self.$i18n.locale(),
        {
          contact_person: self.form.contact_person,
          email1: self.form.email1,
          email2: self.form.email2,
          email3: self.form.email3,
          content: self.form.content,
          nip1: self.form.nip1,
          regon1: self.form.regon1,
          business1: self.form.business1,
          phone1a: self.form.phone1a,
          phone1b: self.form.phone1b,
          phone1c: self.form.phone1c,
          street1: self.form.street1,
          // number1: self.form.number1,
          postal_code1: self.form.postal_code1,
          city1: self.form.city1,
          country_id1: self.form.country_id1,
          provincial_id1: self.form.provincial_id1,
          nip2: self.form.nip2,
          business2: self.form.business2,
          phone2: self.form.phone2,
          street2: self.form.street2,
          // number2: self.form.number2,
          postal_code2: self.form.postal_code2,
          city2: self.form.city2,
          country_id2: self.form.country_id2,
          provincial_id2: self.form.provincial_id2,
          nip3: self.form.nip3,
          business3: self.form.business3,
          phone3: self.form.phone3,
          street3: self.form.street3,
          // number3: self.form.number3,
          postal_code3: self.form.postal_code3,
          city3: self.form.city3,
          country_id3: self.form.country_id3,
          provincial_id3: self.form.provincial_id3,
          countries: self.form.countries,
          provincials: self.form.provincials
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            Swal.fire(
              self.$i18n.translate('forms.transl13'),
              self.$i18n.translate('forms.transl63'),
              'success'
            ).then(function () {
              self.$router.push('/clients');
            });
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              response,
              'error'
            )
          }
        }).catch(function (error) {
        if (error.response.data.message != '') {
          let errorDetails = ""
          for (let key in error.response.data.errors) {
            errorDetails += `${error.response.data.errors[key]}<br/>`
          }
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            errorDetails,
            'error'
          )
          window.scrollTo({top: 0});
        } else {
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            error,
            'error'
          )
          self.$router.push({path: 'login'});
        }
      });
    },

    getDataFromGus(){
      let self = this;
      if(self.form.nip1 === null){
        Swal.fire({
          title: "Błąd",
          text: "Proszę uzupełnić pole NIP",
          type: "error",
          showCancelButton: false,
          confirmButtonText: "Ok",
          reverseButtons: true
        })
      } else {
        axios.get(this.$apiAdress + '/api/getDataFromGus?token=' + localStorage.getItem("api_token") + '&nip=' + self.form.nip1)
          .then(function (response) {
            if(response.data.status === "error"){
              Swal.fire({
                title: "Błąd",
                text: response.data.txt,
                type: "error",
                showCancelButton: false,
                confirmButtonText: "Ok",
                reverseButtons: true
              })
            } else {
              self.form.business1 = response.data.name;
              self.form.nip1 = response.data.nip;
              self.form.regon1 = response.data.regon;
              self.form.postal_code1 = response.data.zipCode;
              self.form.street1 = response.data.street + ' ' +  response.data.apartmentNumber;
              self.form.city1 = response.data.postCity;
            }
          }).catch(function (error) {
          console.log(error);
        });
      }

    },

    getDataFromVies(){
      let self = this;
      if(self.form.nip1 === null){
        Swal.fire({
          title: "Błąd",
          text: "Proszę uzupełnić pole NIP",
          type: "error",
          showCancelButton: false,
          confirmButtonText: "Ok",
          reverseButtons: true
        })
      } else {
        axios.get(this.$apiAdress + '/api/getDataFromVies?token=' + localStorage.getItem("api_token") + '&nip=' + self.form.nip1)
          .then(function (response) {
            if(response.data.status === "error"){
              Swal.fire({
                title: "Błąd",
                text: response.data.txt,
                type: "error",
                showCancelButton: false,
                confirmButtonText: "Ok",
                reverseButtons: true
              })
            } else {
              self.form.business1 = response.data.name;
              // self.form.nip1 = response.data.nip;
              self.form.regon1 = response.data.regon;
              self.form.postal_code1 = response.data.zipCode;
              self.form.street1 = response.data.street;
              self.form.city1 = response.data.city;
            }
          }).catch(function (error) {
          console.log(error);
        });
      }
    },

    submit() {
      this.validate();
      if(!this.$v.form.$invalid) {
        this.store();
      }
    },

    validate() {
      this.$v.$touch()
    },

    reset() {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },

    getEmptyForm() {
      return {
        // name: null,
        // surname: null,
        contact_person: null,
        email1: null,
        email2: null,
        email3: null,
        content: null,
        nip1: null,
        regon1: null,
        business1: null,
        phone1a: null,
        phone1b: null,
        phone1c: null,
        street1: null,
        //number1: null,
        postal_code1: null,
        city1: null,
        country_id1: null,
        provincial_id1: null,
        nip2: null,
        business2: null,
        phone2: null,
        street2: null,
        //number2: null,
        postal_code2: null,
        city2: null,
        country_id2: null,
        provincial_id2: null,
        nip3: null,
        business3: null,
        phone3: null,
        street3: null,
        //number3: null,
        postal_code3: null,
        city3: null,
        country_id3: null,
        provincial_id3: null,
        // countries: null,
        // provincials: null,
      }
    }
  },
  created: function () {
    let self = this;
    axios.get(this.$apiAdress + '/api/clients/create?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.provincials = response.data.provincials;
        self.countries = response.data.countries;
      }).catch(function (error) {
      console.log(error);
      self.$router.push({path: '/login'});
    });
  }
}
</script>

